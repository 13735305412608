var CookiebotDeclaration = Vue.component('cookiebot-declaration', {
    props:{
        domainId: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            // Load external js
            const script = document.createElement('script');
            script.src = 'https://consent.cookiebot.com/' + this.domainId + '/cd.js';
            document.getElementById('app').appendChild(script);

            this.$root.cookiebotDeclarationLoaded = true;
        }
    }
});
